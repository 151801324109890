import React from 'react';
import { Link } from 'react-router-dom'

const MyHoldings = props => {
    const myHoldingsData = props.data;
    return (<div className="widget widget-recent-post mg-bt-43">
                {/* <h2 className="title-widget mg-bt-23">Recent Post</h2> */}
                <div className="meta-item">
                    <div className="left">
                        <h2> Holdings </h2>
                    </div>
                </div>
                <ul>
                    {
                        myHoldingsData.map((item,index) => (
                            <li key={index} className="box-recent-post">
                                <div className="box-feature"><Link to={`/creator/${item.creatorHandle}`}><img src={item.img} alt="Axies" /></Link></div>
                                <div className="box-content">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Link to={`/creator/${item.creatorHandle}`} className="title-recent-post">{item.title}</Link>
                                        <Link to={`/creator/${item.creatorHandle}#buySellSection`}>
                                            <button style={{padding: '5px 10px'}}>Trade</button>
                                        </Link>
                                    </div>
                                    <span><span className="sub-recent-post">{item.minutes}</span><Link to={`/creator/${item.creatorHandle}`} className="day-recent-post">{item.value}</Link></span>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>);
}

export default MyHoldings;
