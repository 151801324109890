import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import PurchaseServiceModal from './PurchaseServiceModal';

const ServicesCard = props => {
    const data = props.data;
    const creator = props.creator;
    const [serviceData, setServiceData] = useState(null);

    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <section className="tf-section">
                <div className="themesflat-container">
                    <div className="row">
                    <div className="col-md-12">
                            <div className="heading-live-auctions">
                                <h2 className="tf-title pb-20">
                                    Services </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}

                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                    slidesPerView: 2,
                                    },
                                    991: {
                                    slidesPerView: 3,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                    {
                                        data.slice(0,7).map((item,index) => (
                                            <SwiperSlide key={index}>
                                                    <div className="swiper-container show-shadow carousel auctions">
                                                        <div className="swiper-wrapper">
                                                            <div className="swiper-slide">
                                                                <div className="slider-item">										
                                                                    <div className="sc-card-product">
                                                                        <div className="card-media">
                                                                            {/* <Link to="/item-details-01"><img src={item.img} alt="axies" /></Link> */}
                                                                            <img src={item.img} alt={item.creatorName} />
                                                                            {/* <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link> */}
                                                                            <div className="wishlist-button heart"><span className="number-like">{item.wishlist}</span>  </div>
                                                        
                                                                            <div className="button-place-bid">
                                                                                <button onClick={() => 
                                                                                    {
                                                                                        setModalShow(true);
                                                                                        setServiceData(item);
                                                                                }} className="sc-button style-place-bid style bag fl-button pri-3"><span>Purchase Service</span></button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-title">
                                                                            {/* <h5><Link to="/item-details-01">"{item.title}"</Link></h5> */}
                                                                            <h5>"{item.title}"</h5>
                                                                            <div className="tags">{item.tags}</div>
                                                                        </div>
                                                                        <div className="meta-info">
                                                                            <div className="author">
                                                                                <div className="avatar">
                                                                                    <img src={creator.imgCreator} alt="axies" />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <span>Creator</span>
                                                                                    <h6> {creator.creatorName} </h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="price">
                                                                                <span>Price </span>
                                                                                <p> {item.price} min</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>    	
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </SwiperSlide>
                                        ))
                                    }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <PurchaseServiceModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                serviceData = {serviceData}
                creator = {creator}
            />
        </Fragment>
        
    );
}

ServicesCard.propTypes = {
    data: PropTypes.array.isRequired,
}


export default ServicesCard;
