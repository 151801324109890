import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams, useLocation } from 'react-router-dom'
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import img1 from '../assets/images/avatar/avt-3.jpg'
import img2 from '../assets/images/avatar/avt-11.jpg'
import img3 from '../assets/images/avatar/avt-1.jpg'
import img4 from '../assets/images/avatar/avt-5.jpg'
import img5 from '../assets/images/avatar/avt-7.jpg'
import img6 from '../assets/images/avatar/avt-8.jpg'
import img7 from '../assets/images/avatar/avt-2.jpg'
import imgdetail1 from '../assets/images/box-item/images-item-details2.jpg'
import todayPickData from '../assets/fake-data/data-today-pick';
import { getSocialMediaTypeInfo } from '../components/layouts/socialMedia';
import ServicesCard from '../components/layouts/ServicesCard';
import ServicesData from '../assets/fake-data/data-services';
// import BuySellModal from '../components/layouts/BuySellModal';
import BuySellComponent from './BuySell';
import { lp } from '../assets/fake-data/data-balance';


let minutesLp = lp.minutesLp;
let usdLp = lp.usdLp;

const CreatorDetail = () => {
    const location = useLocation();

    useEffect(() => {
        // Check if the hash exists in the URL
        if (location.hash === '#buySellSection') {
            // Scroll to the section with the 'buySellSection' id when the component mounts
            const buySellSection = document.getElementById('buySellSection');
            if (buySellSection) {
                buySellSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const findMatchingCreator = (creatorHandle) => {
        for (let i = 0; i < todayPickData.length; i++) {
            const creatorInfo = todayPickData[i];
            if (creatorHandle === creatorInfo.creatorHandle) {
                console.log("creatorInfo: ", creatorInfo)
                return creatorInfo;
            }
        }
    }

    const minToUsd = (usdLp / minutesLp).toFixed(2);


    const { creatorHandle } = useParams();
    
    // const item = todayPickData[0];
    const item = findMatchingCreator(creatorHandle);
    // const [buySellModalShow, setBuySellModalShow] = useState(false);

    const [dataHistory] = useState(
        [
            {
                img: img1,
                name:"Mason Woodward",
                time: "8 hours ago",
                actionType: "bought",
                price: "5 Min",
                priceChange: "$27 USD"
            },
            {
                img: img2,
                name:"Jack Wilson",
                actionType: "sold",
                time: "9 hours ago",
                price: "10 Min",
                priceChange: "$55 USD"
            },
            {
                img: img3,
                name:"Tom Ivey",
                actionType: "bought",
                time: "1 day ago",
                price: "5 Min",
                priceChange: "$30 USD"
            },
            {
                img: img4,
                name:"Linus Love",
                actionType: "bought",
                time: "2 days ago",
                price: "5min",
                priceChange: "$27 USD"
            },
            {
                img: img4,
                name:"Jack Wilson",
                actionType: "bought",
                time: "3 days ago",
                price: "10 Min",
                priceChange: "$50 USD"
            },
        ]
    )
    return (
        <div className='item-details'>
            <Header />
            {/* <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Bobby Alhotff </h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Explore</Link></li>
                                    <li>Item Details 2</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section> */}
            <div className="tf-section tf-item-details style-2">
                <div className="themesflat-container" style={{marginTop: '50px'}}>
                    {/* <div className="page-title-heading mg-bt-12">
                            <h2 className="heading text-left"> Bobbi Althoff </h2>
                    </div> */}
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={item.imgCreator} alt="Axies" />
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    {/* <div className="meta-info">
                                            <div className="author">
                                                <div className="info">
                                                    <span>Followers</span>
                                                    <h6>{item.followers}</h6>
                                                </div>
                                                <div className="avatar">
                                                    <img src={item.imgFollowers} alt="Followers" />
                                                </div>
                                            </div>
                                            <div className="author">
                                                <div className="info">
                                                    <span>Current Views</span>
                                                    <h6>{item.views}</h6>
                                                </div>
                                                <div className="avatar">
                                                    <img src={item.imgViews} alt="Views" />
                                                </div>
                                            </div>
                                        </div> */}
                                    <div className="meta-item">
                                        <div className="left">
                                            <h2> {item.creatorName}</h2>
                                        </div>
                                        <div className="right">
                                            <span className="viewed eye mg-r-8">225</span>
                                            <span to="/login" className="liked heart wishlist-button"><span className="number-like">100</span></span>
                                        </div>
                                    </div>
                                    {/* <div className="client-infor sc-card-product">
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={item.imgFollowers} alt="Axies"/>
                                                </div>
                                                <div className="info">
                                                    <span>Followers</span>
                                                    <h6> {item.followers} </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={img7} alt="Axies" />
                                                </div>
                                                <div className="info">
                                                    <span>Create By</span>
                                                    <h6> <Link to="/author-02">Freddie Carpenter</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <p>{item.creatorDescription}</p>
                                    <div className="meta-item-details"  >
                                        <div className="item-style-2 item-details">
                                            <ul className="list-details">
                                                <li><span>Creator Handle : </span><h6>@{item.creatorHandle}</h6> </li>
                                                <li><span>Platform : </span><h6>{item.socialMediaType}</h6> </li>
                                                <li id="buySellSection"><span>Followers : </span><h6>{item.followers}</h6> </li>
                                                {/* <li><span>Current Views : </span><h6>{item.views}</h6> </li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <div className="meta-item">
                                        <div className="left">
                                            <h3> Activity </h3>
                                        </div>
                                    </div> */}
                                    {/* <ServicesCard data={ServicesData} creator={item}/> */}
                                    <div>
                                        <BuySellComponent creatorHandle = {creatorHandle} usdLp={usdLp} minutesLp={minutesLp}/>
                                    </div>
                                    {/* <Link to="/wallet-connect" className="sc-button loadmore style bag fl-button pri-3"><span>Place a bid</span></Link> */}
                                    {/* <div className="meta-item-details">
                                        <div className="item-style-2 item-details">
                                            <ul className="list-details">
                                                <li><span>Creator Handle : </span><h6>{item.creatorHandle}</h6> </li>
                                                <li><span>Platform : </span><h6>{item.socialMediaType}</h6> </li>
                                                <li><span>Followers : </span><h6>{item.followers}</h6> </li>
                                                <li><span>Current Views : </span><h6>{item.views}</h6> </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <button className="sc-button loadmore style bag fl-button pri-3"  onClick={() => setBuySellModalShow(true)}><span>Place a bid</span></button> */}

                                    <div className="flat-tabs themesflat-tabs">
                                    <Tabs>
                                        <TabList>
                                        <Tab>Info</Tab>
                                        <Tab>Trading History</Tab>
                                        {/* <Tab>Provenance</Tab> */}
                                        </TabList>

                                        <TabPanel>
                                            <ul className="bid-history-list">
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    {/* <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div> */}
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> Current Price </h6> <span> {minToUsd}usd / min</span>
                                                                        </div>
                                                                        {/* <span className="time">8 hours ago</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    {/* <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div> */}
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> 24h High </h6> <span> {(minToUsd * 1.5).toFixed(2)}usd / min</span>
                                                                        </div>
                                                                        {/* <span className="time">8 hours ago</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    {/* <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div> */}
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> 24h Low </h6> <span>  {(minToUsd / 1.25).toFixed(2)}usd / min</span>
                                                                        </div>
                                                                        {/* <span className="time">8 hours ago</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    {/* <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div> */}
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> Circulating Supply </h6> <span> 1500 min</span>
                                                                        </div>
                                                                        {/* <span className="time">8 hours ago</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    {/* <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div> */}
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> Total Trading Volume</h6> <span> 2000 min</span>
                                                                        </div>
                                                                        {/* <span className="time">8 hours ago</span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                            </ul>
                                        </TabPanel>

                                        <TabPanel>
                                            <ul className="bid-history-list">
                                                {
                                                    dataHistory.map((item, index) => (
                                                        <li key={index} item={item}>
                                                            <div className="content">
                                                                <div className="client">
                                                                    <div className="sc-author-box style-2">
                                                                        {/* <div className="author-avatar">
                                                                            <Link to="#">
                                                                                <img src={item.img} alt="Axies" className="avatar" />
                                                                            </Link>
                                                                            <div className="badge"></div>
                                                                        </div> */}
                                                                        <div className="author-infor">
                                                                            <div className="name">
                                                                                <h6><Link to="/author-01">{item.name} </Link></h6> <span> {item.actionType}</span>
                                                                            </div>
                                                                            <span className="time">{item.time}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="price">
                                                                    <h5>{item.price}</h5>
                                                                    <span>for {item.priceChange}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </TabPanel>
                                        {/* <TabPanel>
                                            <div className="provenance">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                                    It has survived not only five centuries, but also the leap into electronic typesetting, 
                                                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                                    and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                            </div>
                                        </TabPanel> */}
                                    </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <LiveAuction data={ServicesData} /> */}

            <ServicesCard data={ServicesData} creator={item}/>
            

            <Footer />
        </div>
    );
}

export default CreatorDetail;
