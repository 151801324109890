import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import CardModal from '../CardModal';
import CardModal2 from '../CardModalUserWaitlist';
import { getSocialMediaTypeInfo } from '../socialMedia';
import CardModalUserWaitlist from '../CardModalUserWaitlist';
import CardModalCreatorWaitlist from '../CardModalCreatorWaitlist'

const TodayPicks = props => {
    const data = props.data;

    /* item testing */
    // const todayPick1 = data[0];
    // const popularItem = {
    //     title: "Creative Art Collection",
    //     imgAuthor: todayPick1.imga1,
    //     name: "Ralph Garraway",
    //     img: todayPick1.img1,
    //     wishlist: "100",
    // }

    const [visible , setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }

    const [waitlistUserModalShow, setWaitlistUserModalShow] = useState(false);
    const [waitlistCreatorModalShow, setWaitlistCreatorModalShow] = useState(false);

    const [creatorSocialPlatform, setCreatorSocialPlatform] = useState(null);
    const [creatorSocialHandle, setCreatorSocialHandle] = useState("");


    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    return (
        <Fragment>
            <section className="tf-section today-pick">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions mg-bt-21">
                                <h2 className="tf-title pad-l-7">
                                    Today's Picks
                                </h2>
                                {/* <Link to="/explore-03" className="exp style2">Invest In Me</Link> */}
                                <div className="exp style2" onClick={() => setWaitlistCreatorModalShow(true)}>Invest In Me</div>
                                {/* <Link to="/explore-03" className="underlineLink">Invest In Me</Link> */}
                                {/* <a style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }} href="https://www.example.com">clickable link</a> */}
                            </div>
                        </div>
                        {
                            data.slice(0,visible).map((item,index) => (
                                <div key={index} className="fl-item col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                    <div className={`sc-card-product menu_card style2 ${item.feature ? 'comingsoon' : '' } `}>
                                        {/* <div className="meta-info style"> */}
                                            {/* <div className="author"> */}
                                                {/* <div className="avatar">
                                                    <img src={item.imgCollection} alt="Axies" />
                                                </div> */}
                                                {/* <div className="info">
                                                    <span>Collection</span>
                                                    <h6> <Link to="/author-02">{item.nameCollection}</Link> </h6>
                                                </div> */}
                                            {/* </div> */}
                                            {/* <div className="menu_card">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i className="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu style={{ margin: 0 }}>
                                                    <Dropdown.Item href="#">
                                                        Refresh Metadata
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                        Share
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#">
                                                        Report
                                                    </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div> */}
                                        {/* </div> */}
                                {/* <img src={item.img} alt="Axies" /> */}
                                {/* <div className="content">
                                    <div className="fs-16"><Link to="/item-details-01">{item.title}</Link></div>
                                    <p>{item.category}</p>
                                </div>    */}
                                        <div className="card-media">
                                            <Link to={`/creator/${item.creatorHandle}`}> <img src={item.imgCreator} alt="creator" /> </Link>
                                            {/* <Link to="/item-details-01"><img src={item.imgCreator} alt="creator" /></Link> */}
                                            {/* <Link to="/login" className="wishlist-button heart"><span className="number-like">{item.wishlist}</span></Link> */}
                                            <div className="coming-soon">{item.feature}</div>
                                        </div>
                                        <div className="card-title">
                                                <h5 className="style2"><Link to={item.creatorProfileLink}>{item.creatorHandle}</Link></h5>

                                                <div> <img src={getSocialMediaTypeInfo(item.socialMediaType).socialMediaImgSrc} alt={getSocialMediaTypeInfo(item.socialMediaType).socialMediaAlt} style={{
                                                    borderRadius: '50%',
                                                    width: '35px',
                                                    height: '35px',
                                                    objectFit: 'cover',
                                                    marginRight: '10px'
                                                }}/></div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="info">
                                                    <span>Followers</span>
                                                    <h6>{item.followers}</h6>
                                                </div>
                                                <div className="avatar">
                                                    <img src={item.imgFollowers} alt="Followers" />
                                                </div>
                                            </div>
                                            <div className="author">
                                                <div className="info">
                                                    <span>Current Views</span>
                                                    <h6>{item.views}</h6>
                                                </div>
                                                <div className="avatar">
                                                    <img src={item.imgViews} alt="Views" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content">
                                        <p style={{ marginTop: '20px' }}>{item.creatorDescription} </p>
                                        </div>
                                        <div className="card-bottom" style={containerStyle}>
                                            <button style={{ width: '300px', padding: '10px 20px' }} 
                                                onClick={() => {
                                                    setWaitlistUserModalShow(true);
                                                    setCreatorSocialHandle(item.creatorHandle);
                                                    setCreatorSocialPlatform(item.socialMediaType);
                                                }} 
                                                className="sc-button style bag fl-button pri-3 no-bg"><span>Invest</span>
                                            </button>
                                            {/* <Link to="/activity-01" className="view-history reload">View History</Link> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            visible < data.length && 
                            <div className="col-md-12 wrap-inner load-more text-center"> 
                                <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                            </div>
                        }
                    </div>
                </div>
            </section>
            <CardModalUserWaitlist
                show={waitlistUserModalShow}
                onHide={() => setWaitlistUserModalShow(false)}
                socialPlatform={creatorSocialPlatform}
                socialHandle={creatorSocialHandle}
            />
            <CardModalCreatorWaitlist
                show={waitlistCreatorModalShow}
                onHide={() => setWaitlistCreatorModalShow(false)}
            />

        </Fragment>
    );
}


// const [data] = useState(
//     [
//         {
//             title: "Creative Art Collection",
//             imgAuthor: imgAuthor1,
//             name: "Ralph Garraway",
//             img: img1,
//             wishlist: "100",
//         },
//         {
//             title: "Colorful Abstract",
//             imgAuthor: imgAuthor2,
//             name: "Mason Woodward",
//             img: img2,
//             wishlist: "100",
//         },
//         {
//             title: "Modern Art Collection",
//             imgAuthor: imgAuthor3,
//             name: "Freddie Carpenter",
//             img: img3,
//             wishlist: "100",
//         },
//         {
//             title: "Creative Art Collection",
//             imgAuthor: imgAuthor1,
//             name: "Ralph Garraway",
//             img: img1,
//             wishlist: "100",
//         },
//         {
//             title: "Colorful Abstract",
//             imgAuthor: imgAuthor2,
//             name: "Mason Woodward",
//             img: img2,
//             wishlist: "100",
//         },
//         {
//             title: "Modern Art Collection",
//             imgAuthor: imgAuthor3,
//             name: "Freddie Carpenter",
//             img: img3,
//             wishlist: "100",
//         },
//     ]
// )

const PopularCollectionItem = props => (
    <div className="slider-item">										
    <div className="sc-card-collection style-2 home2">
        <div className="card-media-h6">
            <img src={props.item.img} alt="Axies" />
        </div>
        <div className="card-bottom">
            <div className="author">
                <div className="sc-author-box style-2">
                    <div className="author-avatar">
                        <img src={props.item.imgAuthor} alt="Axies" className="avatar" />
                        <div className="badge"><i className="ripple"></i></div>
                    </div>
                </div>
                <div className="content">
                    <h4><Link to="/authors-01">{props.item.title}</Link></h4>
                    <div className="infor">
                        <span>Created by</span>
                        <span className="name"><Link to="/author-02">{props.item.name}</Link></span>
                    </div>
                </div>
            </div>
            <Link to="/login" className="wishlist-button public heart mg-t-6"><span className="number-like">{props.item.wishlist}</span></Link>
        </div>
    </div> 		
</div>
)



TodayPicks.propTypes = {
    data: PropTypes.array.isRequired,
}

export default TodayPicks;
