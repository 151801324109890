import img1 from "../images/box-item/card-item8.jpg";
import imga1 from "../images/avatar/avt-11.jpg";
import img2 from "../images/box-item/image-box-10.jpg";
import imga2 from "../images/avatar/avt-12.jpg";
import img3 from "../images/box-item/card-item-11.jpg";
import imga3 from "../images/avatar/avt-13.jpg";
import img4 from "../images/box-item/image-box-21.jpg";
import imga4 from "../images/avatar/avt-14.jpg";
import imgCollection1 from "../images/avatar/avt-18.jpg";
import imgCollection2 from "../images/avatar/avt-16.jpg";
import imgCollection3 from "../images/avatar/avt-17.jpg";

const MyServicesData = [
  {
    creatorHandle: "jayhoovy",
    creatorName: "Jay Hoovy",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvaQ-jTNSy1Qo1VdS172mw-UCa_lK0TuQm9pqItC8Ph42co6cWCR01eCNCAuzQoxIPfRU&usqp=CAU",
    title: "Direct Message",
    description: "Send one direct message to the desired creator.",
    tags: "text",
    // imgAuthor:
    //   "https://yt3.googleusercontent.com/ILzXyLo1596fGKpjFWXigDRQGjReXoUGEEbPy9tLQ8I0_Hr2Qu10w1aQMLPXJlj-d1aK0o1nk84=s900-c-k-c0x00ffffff-no-rj",
    // nameAuthor: "JayHoovy",
    price: 2,
    wishlist: "220",
    count: 3,
    // imgCollection: "imgCollection2",
    // nameCollection: "Colorful Abstract",
  },
  {
    creatorName: "Bobbi Althoff",
    creatorHandle: "bobbialthoff",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvaQ-jTNSy1Qo1VdS172mw-UCa_lK0TuQm9pqItC8Ph42co6cWCR01eCNCAuzQoxIPfRU&usqp=CAU",
    title: "Direct Message",
    description: "Send one direct message to the desired creator.",
    tags: "text",
    // imgAuthor:
    //   "https://yt3.googleusercontent.com/ILzXyLo1596fGKpjFWXigDRQGjReXoUGEEbPy9tLQ8I0_Hr2Qu10w1aQMLPXJlj-d1aK0o1nk84=s900-c-k-c0x00ffffff-no-rj",
    // nameAuthor: "JayHoovy",
    price: 2,
    wishlist: "220",
    count: 2,
    // imgCollection: "imgCollection2",
    // nameCollection: "Colorful Abstract",
  },
  {
    creatorName: "Bobbi Althoff",
    creatorHandle: "bobbialthoff",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRk6bAJLxHzZn0c5ON_8E7dmKOncseYq9gGMg&usqp=CAU",
    title: "Join Groupchat",
    description: "Join others and chat together with the desired creator.",
    tags: "video",
    // imgAuthor:
    //   "https://yt3.googleusercontent.com/ILzXyLo1596fGKpjFWXigDRQGjReXoUGEEbPy9tLQ8I0_Hr2Qu10w1aQMLPXJlj-d1aK0o1nk84=s900-c-k-c0x00ffffff-no-rj",
    // nameAuthor: "Bobbi Althoff",
    price: 120,
    // price: "1.32 ETH",
    // priceChange: "$60.246",
    wishlist: "100",
    count: 1,
    // imgCollection: imgCollection1,
    // nameCollection: "Colorful Abstract",
  },
  {
    creatorHandle: "sadiapsychology",
    creatorName: "Sadia",
    img: "https://blog.zoom.us/wp-content/uploads/2020/06/Video-Conferencing--1024x632.png",
    title: "Online Meeting",
    description: "Meet online with the desired creator for 20 minutes.",
    tags: "video",
    // imgAuthor:
    //   "https://yt3.googleusercontent.com/ILzXyLo1596fGKpjFWXigDRQGjReXoUGEEbPy9tLQ8I0_Hr2Qu10w1aQMLPXJlj-d1aK0o1nk84=s900-c-k-c0x00ffffff-no-rj",
    // nameAuthor: "Bobbi Althoff",
    price: 240,
    // price: "1.32 ETH",
    // priceChange: "$60.246",
    wishlist: "100",
    count: 2,
    // imgCollection: imgCollection1,
    // nameCollection: "Colorful Abstract",
  },
];

export default MyServicesData;
