import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

const CardModal = (props) => {
    
return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body space-y-20 pd-40">
        <h3>Testing</h3>
        <p className="text-center">Enter your email below
        </p>
        <input type="text" className="form-control"/>
        <p>How much do you want to invest?</p>
        <input type="number" className="form-control" placeholder="1" />
        <div className="hr"></div>
        <Link to="/wallet-connect" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</Link>
    </div>
    </Modal>
    
  );
};

export default CardModal;
