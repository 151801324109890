import React, { useState } from 'react';
import todayPickData from '../../assets/fake-data/data-today-pick'
import { SocialMediaTypes } from './socialMedia'
import { submitUserForm } from '../../util/backendFunctions';

const WaitlistFormUser = props => {

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    creator: props.socialHandle ? props.socialHandle : '',
    platform: props.socialPlatform ? props.socialPlatform : '',
    creatorHandle: '',
    investment: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    setSubmitted(true);
    window.fbq('track', 'submit-form');
    await submitUserForm(formData);
  };

  const inputHeaderStyle = {
    fontSize: '16px',  // Adjust the font size as needed.
    padding: '10px',   // Add more padding for a larger feel.
    width: '100%',     // Make the input take the full width of its container.
  };

  const inputFieldStyle = {
    border: '1px solid #ccc', 
    fontSize: '16px',  // Adjust the font size as needed.
    padding: '10px',   // Add more padding for a larger feel.
    width: '100%',     // Make the input take the full width of its container.
    marginBottom: '20px',
    WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
    MozAppearance: 'none',       // Remove default appearance on Firefox
    appearance: 'none', 
    marginLeft: '10px'    
  };

  const selectStyle = {
    background: 'transparent',    // Transparent background
    border: '1px solid #ccc',    // A simple border like most text inputs
    fontSize: '16px',            // Making font size consistent with text inputs
    padding: '10px',             // Padding to give it some space
    width: '100%',               // Taking the full width of its container
    marginBottom: '20px',        // Bottom margin for spacing
    WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
    MozAppearance: 'none',       // Remove default appearance on Firefox
    appearance: 'none', 
    marginLeft: '10px'         // General reset
  };

  // if (submitted) {
  //   return <div>Thank you for joining our waitlist! We'll notify you once the product goes live.</div>;
  // }
  if (submitted) {
    return (
        <div className="modal-body space-y-20 pd-40">
            <h1 className="heading" style={{marginBottom: '40px'}}>Get Ready!</h1>
            <p className="sub-heading">
                Thank you for joining our waitlist! We'll notify you once the product goes live.
            </p>
        </div>
    );
  }

  return (
    <div className="modal-body space-y-20 pd-40">
      <h3>Sign Up</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="fullName"  style={inputHeaderStyle}>
            Your Name:
            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          </label>
          
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            style={inputFieldStyle}
          />
        </div>
        <div>
          <label htmlFor="email" style={inputHeaderStyle}>
            Email Address:
            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={inputFieldStyle}
          />
        </div>
        <div>
          <label htmlFor="creator" style={inputHeaderStyle}>Creator you want to invest in right now:</label>
          <select
            id="creator"
            name="creator"
            value={formData.creator}
            onChange={handleChange}
            style={selectStyle}
          >
            <option value="" selected disabled>Select an option</option>
            {Object.values(todayPickData).map(creatorData => (
                <option key={creatorData.creatorHandle} value={creatorData.creatorHandle}>
                    {creatorData.creatorHandle}
                </option>
            ))}
            {/* <option value="John Doe">John Doe</option>
            <option value="Jane Smith">Jane Smith</option>
            <option value="Alice">Alice</option>
            <option value="Bob">Bob</option> */}
            <option value="other">Other</option>
          </select>
        </div>
        {formData.creator === 'other' && (
          <>
            <div>
              <label htmlFor="creatorHandle" style={inputHeaderStyle}>Creator Handle:</label>
              <input
                type="text"
                id="creatorHandle"
                name="creatorHandle"
                value={formData.creatorHandle}
                onChange={handleChange}
                style = {selectStyle}
                placeholder='@elonmusk'
              />
            </div>
          </>
        )}
        <div>
          <label htmlFor="platform" style={inputHeaderStyle}>Platform:</label>
          <select
            id="platform"
            name="platform"
            value={formData.platform}
            onChange={handleChange}
            style = {selectStyle}
          >
            <option value="" selected disabled>Select an option</option>
            {Object.values(SocialMediaTypes).map(socialMediaType => (
                <option key={socialMediaType} value={socialMediaType}>
                    {socialMediaType}
                </option>
            ))}

            {/* <option value="TikTok">TikTok</option>
            <option value="YouTube">YouTube</option>
            <option value="Instagram">Instagram</option>
            <option value="Twitter">Twitter</option>
            <option value="Twitch">Twitch</option>
            <option value="Linkedin">Linkedin</option>
            <option value="Reddit">Reddit</option>
            <option value="Quora">Quora</option> */}
          </select>
        </div>
        {/* <div>
          <label htmlFor="investment" style={inputHeaderStyle}>How much approximately do you want to invest in the chosen creator:</label>
              <select id="investment"
                name="investment"
                value={formData.investment}
                onChange={handleChange}
                style = {selectStyle}
              >
              <option value="" selected disabled>Select an option</option>
              <option value="0-500">0-500</option>
              <option value="500-1000">500-1000</option>
              <option value="1000-2500">1000-2500</option>
              <option value="2500-5000">2500-5000</option>
              <option value="5000-10000">5000-10000</option>
              <option value="10000-20000">10000-20000</option>
              <option value="20000-50000">20000-50000</option>
              <option value="50000-100000">50000-100000</option>
              <option value="100000+">100000+</option>
          </select>
        </div> */}

        <div style={{ textAlign: 'center' }}>
          <button type="submit">Join Now!</button>
        </div>
      </form>
    </div>
  );
}

export default WaitlistFormUser;
