const SocialMediaTypes = Object.freeze({
    TIKTOK: "TikTok",
    YOUTUBE: 'YouTube',
    INSTAGRAM: 'Instagram',
    TWITTER: "Twitter",
    TWITCH: "Twitch",
    LINKEDIN: "Linkedin",
    REDDIT: "Reddit",
    QUORA: "Quora",
    FACEBOOK: "Facebook",
  });
  
const getSocialMediaTypeInfo = (socialMediaType) => {
    switch(socialMediaType) {
        case SocialMediaTypes.TIKTOK:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.TIKTOK + " logo"
            }
        case SocialMediaTypes.TWITTER:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.TWITTER + " logo"
            }
        case SocialMediaTypes.YOUTUBE:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://static.vecteezy.com/system/resources/thumbnails/023/986/704/small/youtube-logo-youtube-logo-transparent-youtube-icon-transparent-free-free-png.png",
                socialMediaAlt: SocialMediaTypes.YOUTUBE + " logo"
            }
        case SocialMediaTypes.INSTAGRAM:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.INSTAGRAM + " logo"
            }
        case SocialMediaTypes.TWITCH:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.TWITCH + " logo"
            }
        case SocialMediaTypes.LINKEDIN:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.LINKEDIN + " logo"
            }
        case SocialMediaTypes.REDDIT:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.REDDIT + " logo"
            }
        case SocialMediaTypes.QUORA:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.QUORA + " logo"
            }
        case SocialMediaTypes.FACEBOOK:
            return {
                socialMediaType: socialMediaType,
                socialMediaImgSrc: "https://w7.pngwing.com/pngs/814/840/png-transparent-tiktok-tiktok-logo-tiktok-icon-thumbnail.png",
                socialMediaAlt: SocialMediaTypes.FACEBOOK + " logo"
            }
        default:
            return {
                socialMediaType: null,
                socialMediaImgSrc: null,
                socialMediaAlt: null
            }
    }
} 

export {SocialMediaTypes, getSocialMediaTypeInfo};
