import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import MyHoldingsData from '../../assets/fake-data/data-my-holdings';
import CardModalDepositForm from './CardModalDepositForm';
import DepositForm from './DepositForm';
import { signOut } from "firebase/auth";
import { auth } from '../../util/authentication';



const MySettings = props => {
    const navigate = useNavigate();
    const [withdrawalModalShow, setWithdrawalModalShow] = useState(false);

    const handleLogoutOnClick = async () => {
        localStorage.removeItem("user");
        await signOut(auth);
        navigate("/");
        // window.location.reload();
    }

    return  (
        <div>
            <div className="meta-item" >
                <div className="left">
                        <h2> Settings </h2>
                </div>
                <div className="right">
                    {/* <button className="icon-fl-logout" onClick={handleLogoutOnClick} > Logout</button> */}
                    <button className="icon-fl-note" onClick={handleLogoutOnClick} > Logout</button>
                    {/* <span className="viewed eye mg-r-8">225</span>
                    <span to="/login" className="liked heart wishlist-button"><span className="number-like">100</span></span> */}
                </div>
                {/* <div className="sc-button style-1 fl-button pri-3" style={{marginTop: '25px',}}>
                    <i className="icon-fl-google-2"></i>
                </div> */}
            </div>
            
        </div>);
}

export default MySettings;
