const MyInvestmentsData = {
  userHandle: "Xuanzhen Zhang",
  userEmail: "xz@gmail.com",
  userPhone: +144565193355,
  totalInvestmentValue: 1000,
  usdBalanceValue: 500,
  creatorHoldingsValue: 400,
  servicesHoldingsValue: 100,
};

export default MyInvestmentsData;
