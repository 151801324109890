import React, { useState } from 'react';
import { userBalanceData } from '../assets/fake-data/data-balance'
import BuySellConfirmationModal from '../components/layouts/BuySellConfirmationModal';
// import { submitCreatorForm } from '../../util/backendFunctions'

const BuySellComponent = props => {
//     const totalUsd = 1000;  // Assume this is the total USD amount the user has
//   const amountToPay = 200;  // Assume this is the amount the user is paying
//   const remainingUsd = totalUsd - amountToPay;

//   return (
//     <div style={{
//       border: '1px solid #ccc',
//       borderRadius: '4px',
//       padding: '8px',
//       width: '200px',
//       fontFamily: 'Arial, sans-serif',
//       fontSize: '14px',
//       lineHeight: '1.5'
//     }}>
//       <div style={{ marginBottom: '4px' }}>USD {amountToPay.toFixed(2)}</div>
//       <div style={{ fontSize: '12px', color: 'grey' }}>Remaining: USD {remainingUsd.toFixed(2)}</div>
//     </div>
//   );

    const usdLp = props.usdLp;
    const minutesLp = props.minutesLp;

    const invariant = usdLp * minutesLp;
    const minToUsd = Number((usdLp / minutesLp).toFixed(2));
    const feeRate = 0.98;

    const creatorHandle = props.creatorHandle;
    console.log("creatorHandle: ", creatorHandle)


    const [formData, setFormData] = useState({
        minutes: null,
        usd: null,
    });
    const [submitted, setSubmitted] = useState(false);
    const [isBuy, setIsBuy] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData);
        setSubmitted(true);
        // window.fbq('track', 'submit-form');
        // await submitCreatorForm(formData);
    };

    const inputHeaderStyle = {
        fontSize: '16px',  // Adjust the font size as needed.
        // padding: '10px',   // Add more padding for a larger feel.
        width: '100%',     // Make the input take the full width of its container.
        marginBottom: "30px"
    };

    const inputFieldStyle = {
        border: '1px solid #ccc', 
        fontSize: '16px',  // Adjust the font size as needed.
        padding: '10px',   // Add more padding for a larger feel.
        width: '100%',     // Make the input take the full width of its container.
        marginBottom: '20px',
        WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
        MozAppearance: 'none',       // Remove default appearance on Firefox
        appearance: 'none',   
    };

    const selectStyle = {
        background: 'transparent',    // Transparent background
        border: '1px solid #ccc',    // A simple border like most text inputs
        fontSize: '16px',            // Making font size consistent with text inputs
        padding: '10px',             // Padding to give it some space
        width: '100%',               // Taking the full width of its container
        marginBottom: '20px',        // Bottom margin for spacing
        WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
        MozAppearance: 'none',       // Remove default appearance on Firefox
        appearance: 'none', 
        marginLeft: '10px'         // General reset
    };

    // const minToUsd = props.minToUsd;

    const calculateMinutesOutputFromUsdInput = (usd) => {
        if (usd === 0) {
            return 0;
        }
        if (isBuy) {
            const minutes = minutesLp - invariant / (usdLp + usd * feeRate);
            return Number(minutes.toFixed(2));
        } else {
            const minutes = (invariant / (usdLp - usd) - minutesLp) / feeRate;
            return Number(minutes.toFixed(2));
        }
    } 

    const calculateUsdOutputFromMinutesInput = (minutes) => {
        if (minutes === 0) {
            return 0;
        }
        console.log("invariant: ", invariant);
        console.log("min: ", minutesLp);
        console.log("usd: ", usdLp);
        console.log("min + fee: ", minutesLp + minutes * feeRate);
        if (isBuy) {
            const usd = (invariant / (minutesLp - minutes) - usdLp) / feeRate;
            return Number(usd.toFixed(2));
        } else {
            const usd = usdLp - invariant / (minutesLp + minutes * feeRate);
            return Number(usd.toFixed(2));
        }
    }

    const handleUSDChange = (event) => {
        const usdValue = Number(event.target.value);
        setFormData({
            usd: usdValue,
            minutes: calculateMinutesOutputFromUsdInput(usdValue)
        });
    };

    const handleMinutesChange = (event) => {
        const minutesValue = Number(event.target.value);
        setFormData({
            usd: calculateUsdOutputFromMinutesInput(minutesValue),
            minutes: minutesValue
        });
    };

    // const usdComponent = 
    //     <div>
    //             <label htmlFor="usd" style={inputHeaderStyle}>You Pay: (USD) </label>
    //             <input
    //                 type="number"
    //                 id="usd"
    //                 name="usd"
    //                 value={formData.usd}
    //                 onChange={handleUSDChange}
    //                 required
    //                 style={{ ...inputFieldStyle, paddingRight: '100px' }}  // Adjust padding as necessary
    //             />
    //             <span style={{
    //                 position: 'absolute',
    //                 right: '25px',
    //                 top: '49%',
    //                 transform: 'translateY(0%)',
    //                 fontSize: '12px',
    //                 color: 'grey'
    //             }}>
    //                 Balance: {userBalanceData.usdBalance} USD 
    //             </span>
    //         </div>;

    const usdComponent = (
        <div style={{ ...inputFieldStyle, padding: '8px', border: '1px solid #ccc', borderRadius: '4px', position: 'relative', marginTop: '30px' }}>
            <label htmlFor="usd" style={{ ...inputHeaderStyle, position: 'absolute', top: '-20px', left: '0px' }}>{isBuy ? "You Pay" : "You Receive"}: (USD) </label>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <input
                    type="number"
                    id="usd"
                    name="usd"
                    value={formData.usd}
                    onChange={handleUSDChange}
                    required
                    style={{ flex: 1, border: 'none', outline: 'none', padding: '8px' }}
                />
                <span style={{ padding: '0 8px', fontSize: '12px', color: 'grey' }}>
                    Balance: {userBalanceData.usdBalance} USD
                </span>
            </div>
        </div>
    );
    

    const timeComponent = (
        <div style={{ ...inputFieldStyle, padding: '8px', border: '1px solid #ccc', borderRadius: '4px', position: 'relative', marginTop: '30px' }}>
            <label htmlFor="minutes" style={{ ...inputHeaderStyle, position: 'absolute', top: '-20px', left: '0px' }}>{isBuy ? "You Receive" : "You Sell"}: (minutes)</label>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <input
                    type="number"
                    id="minutes"
                    name="minutes"
                    value={formData.minutes}
                    onChange={handleMinutesChange}
                    style={{ flex: 1, border: 'none', outline: 'none', padding: '8px' }}
                />
                <span style={{ padding: '0 8px', fontSize: '12px', color: 'grey' }}>
                    Balance: {userBalanceData.creatorTime[creatorHandle] ? `${userBalanceData.creatorTime[creatorHandle]} Min` : "0 Min"}
                </span>
            </div>
        </div>
    );
    const submitComponent = <div className="sc-button header-slider style style-1 rocket fl-button pri-1" onClick={() => {
                                setSubmitted(true)
                                }}><span> {isBuy ? 'Buy' : 'Sell'}
                            </span></div>;

    const buyComponent = <form onSubmit={handleSubmit} style={{marginTop: '20px'}}>{usdComponent} {timeComponent} {submitComponent} </form>;
    const sellComponent = <form onSubmit={handleSubmit} style={{marginTop: '20px'}}>{timeComponent} {usdComponent} {submitComponent} </form>;

  return (
    <div className="modal-body">
        <div className="tabs" style = {{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '20px'}}>
            <button
            className={isBuy ? 'active-tab' : ''}
            onClick={() => setIsBuy(true)}
            style={{backgroundColor: !isBuy && 'transparent', color: 'white'}}
            >
            Buy
            </button>
            <button
            className={!isBuy ? 'active-tab' : ''}
            onClick={() => setIsBuy(false)}
            style={{backgroundColor: isBuy && 'transparent', color: 'white'}}
            >
            Sell
            </button>
      </div>
        {/* { !isBuy &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', width: '100%', marginBottom: '20px' }}>
                    <button type="submit" onClick={() => {setIsBuy(true)}} style={{backgroundColor: 'transparent'}}> Buy </button>
                </div>
        }   
        { isBuy &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', marginBottom: '20px' }}>
                    <button type="submit" onClick={() => {setIsBuy(false)}} style={{backgroundColor: 'transparent'}}> Sell </button>
                </div>
        } */}
        <h3>{isBuy ? 'Buy' : 'Sell'} Time</h3>
        <p style={{ fontSize: '12px', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>Current price: {minToUsd} usd / minute</p>
        {isBuy ? buyComponent : sellComponent}
        {submitted && <BuySellConfirmationModal show={submitted} onHide={() => setSubmitted(false)} isBuy = {isBuy} transactionForm ={formData}/>}
    </div>
  );
}

export default BuySellComponent;


