import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import WithdrawalForm from './WithdrawalForm';

const CardModalWithdrawalForm = (props) => {
    
return (

    <Modal
        show={props.show}
        onHide={props.onHide}
    >
    <Modal.Header closeButton></Modal.Header>

    <WithdrawalForm/>
    </Modal>

    
  );
};

export default CardModalWithdrawalForm;
