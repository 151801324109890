const MyHoldingsData = [
  {
    img: "https://yt3.googleusercontent.com/ILzXyLo1596fGKpjFWXigDRQGjReXoUGEEbPy9tLQ8I0_Hr2Qu10w1aQMLPXJlj-d1aK0o1nk84=s900-c-k-c0x00ffffff-no-rj",
    creatorHandle: "bobbialthoff",
    title: "Bobbi Althoff",
    minutes: "Minutes: 30",
    value: "Current Value: $300",
  },
  {
    img: "https://pbs.twimg.com/profile_images/1409678692654239744/hJyVzRKm_400x400.jpg",
    creatorHandle: "jayhoovy",
    title: "Jay Hoovy",
    minutes: "Minutes: 15",
    value: "Current Value: $60",
  },
  {
    img: "https://lirp.cdn-website.com/6ce9ac5d/dms3rep/multi/opt/IMG_9657-1ef969a9-640w.JPG",
    creatorHandle: "sadiapsychology",
    title: "Sadia Psychology",
    minutes: "Minutes: 20",
    value: "Current Value: $40",
  },
];

export default MyHoldingsData;
