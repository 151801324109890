import React, { useEffect, useState }from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { signInWithGoogle, signUpWithEmail, signInWithEmail, resetPassword, auth, signInWithPhoneSendCode } from '../util/authentication';
import { useNavigate } from "react-router-dom";
import {
    onAuthStateChanged,
    RecaptchaVerifier
} from "firebase/auth";
  


const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState(null);


    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [confirmation, setConfirmation] = useState(null);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


    useEffect(() => {
        // Initialize the reCAPTCHA verifier
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved - you can proceed with phone number verification
                console.log('Recaptcha resolved with response:', response);
            },
        }, {
            'sitekey': '6LeuYsUoAAAAAL5ZgkPy4s1Tty3JTY29X0M2sPXF' // Replace with your reCAPTCHA Site Key
        });

        // This will render the reCAPTCHA widget
        window.recaptchaVerifier.render().then(function(widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
    }, []);


    const handleSendCode = async (e) => {
        e.preventDefault();
        const result = await signInWithPhoneSendCode(phoneNumber, window.recaptchaVerifier);
        console.log("result: ", result);
        setConfirmation(result);
    }

    const handleConfirmCode = (e) => {
        e.preventDefault();
        console.log("confirmation: ", confirmation)
        console.log("code: ", code);
        confirmation.confirm(code)
            .then((result) => {
                const user = result.user;
                console.log("User logged in:", user);
            })
            .catch((error) => {
                console.error("Error verifying code:", error);
            });
    }

    const setLocalStorageUser = (user) => {
        localStorage.setItem('user', JSON.stringify(user));
    }

    useEffect(() => {
        if (user) {
            // Navigate to the user's dashboard and pass the user data as state
            navigate("/profile");
            window.location.reload();
        }
    }, [user, navigate]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
          // If you need to retrieve additional data or tokens after redirect:
          if (authUser) {
            // Access user details and possibly tokens or other info here
            console.log(authUser);
            setLocalStorageUser(authUser);
            setUser(authUser);
          }
        });
    
        // Cleanup listener on component unmount
        return () => unsubscribe();
    
    }, []);



    const handlePasswordReset = async () => {
        try {
            await resetPassword(email);
            alert('Email sent successfully!')
            // Navigate to the user's dashboard and pass the user data as state
            // navigate("/profile", { state: { user } });
        } catch (error) {
            console.error("Error during password reset:", error);
        }
    }

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithGoogle();
            // console.log("result: ", result);
        } catch (error) {
            console.error("Error during Google Sign-In:", error);
        }
    };

    const handleLoginWithEmail = async (e) => {
        try {
            e.preventDefault();
            const result = await signInWithEmail(email, password);
            // alert('Signed in successfully!')
        } catch (error) {
            console.error("Error during Email Sign-In:", error);
        }
    };

    const handleSignUpWithEmail = async (e) => {
        try {
            e.preventDefault();
            const result = await signUpWithEmail(email, password);
        } catch (error) {
            console.error("Error during Email Sign-In:", error);
        }
    };

    const SocialLogin =
        <div className="flat-form box-login-social">
            <div className="box-title-login">
                <h5>Login with Social</h5>
            </div>
            <ul>
                <li>
                    <div className="sc-button style-2 fl-button pri-3" onClick={handleGoogleLogin}>
                        <i className="icon-fl-google-2"></i>
                        <span>Google</span>
                    </div>
                </li>
                {/* <li>
                    <Link to="#" className="sc-button style-2 fl-button pri-3">
                        <i className="icon-fl-facebook"></i>
                        <span>Facebook</span>
                    </Link>
                </li> */}
            </ul>
        </div>

    const EmaiLogin = 
        <div className="flat-form box-login-email">
            <div className="box-title-login">
                <h5>Or login with email</h5>
            </div>

            <div className="form-inner">
                <form id="contactform">
                    {/* <input id="name" name="name" tabIndex="1" aria-required="true" required type="text" placeholder="Your Full Name" /> */}
                    <input id="email" name="email" tabIndex="1" type="email" placeholder="Your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    {!isForgotPassword && <input id="password" name="password" tabIndex="2" type={showPassword ? "text" : "password"}  placeholder="Your Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>}
                    
                    <div className="row-form style-1">
                        {/* <Link to="#" className="forgot-pass">Forgot Password?</Link> */}
                        {
                            isForgotPassword ?
                                <div className="forgot-pass" onClick={() => setIsForgotPassword(false)}  style={{ textDecoration: 'underline' }}>Sign in with existing Email</div>
                                :
                                <>
                                    <label>Show Password
                                        <input 
                                            type="checkbox" 
                                            checked={showPassword} 
                                            onChange={() => setShowPassword(!showPassword)}
                                        />
                                        <span className="btn-checkbox"></span>
                                    </label>
                                    <div className="forgot-pass" onClick={() => setIsForgotPassword(true)} style={{ textDecoration: 'underline' }}>Forgot Password?</div>
                                </>
                        }
                    </div>

                    {isForgotPassword ?
                        <button 
                            style={{ display: 'inline-block', marginLeft: 'auto' }} 
                            onClick={handlePasswordReset}
                        >
                            Send Email to Reset Password
                        </button>
                        :
                        <div style={{ display: 'flex', justifyContent: 'space-between',  gap: '20px' }}>
                            <button 
                                type='button'
                                style={{ display: 'inline-block' }} 
                                onClick={handleLoginWithEmail}
                            >
                                Login
                            </button>
                            <button 
                                style={{ display: 'inline-block', marginLeft: 'auto' }} 
                                onClick={handleSignUpWithEmail}
                            >
                                Signup
                            </button>
                        </div>}
                </form>
            </div>
        </div>


    return (
        <div>
            <Header />
            {/* <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Login</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Login</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section> */}
            <section className="tf-login tf-section">
                <div className="themesflat-container" style={{marginTop: '50px'}}>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-1">
                                Start Investing in Your Favorite Creators
                            </h2>
                            <div className="flat-form box-login-social">
                                <div className="box-title-login">
                                    <h5>Login with Phone Number</h5>
                                </div>
                                <div className="form-inner">
                                    <form id="contactform">
                                    <div id="recaptcha-container"></div>
                                        {!confirmation ? (
                                            <div style={{ display: 'flex', justifyContent: 'space-between',  gap: '20px' }}>
                                                <input
                                                    id="phone" 
                                                    name="phone"
                                                    tabIndex="1"
                                                    placeholder="E.g.+143536778824"
                                                    value={phoneNumber}
                                                    onChange={e => setPhoneNumber(e.target.value)}
                                                    style={{width: '100%'}}
                                                    type="tel"
                                                    required
                                                />
                                                {/* <input 
                                                    id="phone" 
                                                    name="phone" 
                                                    tabIndex="1" 
                                                    type="text" placeholder="Your Email Address" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required /> */}

                                                <button 
                                                    type="button" 
                                                    style={{
                                                        height: '40px', 
                                                        lineHeight: '30px', // Set the line-height equal to height for vertical centering
                                                        padding: '0',  // Add horizontal padding if needed, remove or adjust vertical padding
                                                        verticalAlign: 'middle', // Ensure the content is vertically aligned
                                                        textAlign: 'center' // Center the text horizontally
                                                    }}  
                                                    onClick={handleSendCode}>Send Code
                                                </button>
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'space-between',  gap: '20px' }}>
                                                <input
                                                    id="code" 
                                                    name="code"
                                                    tabIndex="1"
                                                    placeholder="Verification Code"
                                                    value={code}
                                                    style={{width: '100%'}}
                                                    onChange={e => setCode(e.target.value)}
                                                    type="text"
                                                    required
                                                />
                                                <button 
                                                    type = "button"
                                                    style={{
                                                        height: '40px', 
                                                        lineHeight: '30px', // Set the line-height equal to height for vertical centering
                                                        padding: '0',  // Add horizontal padding if needed, remove or adjust vertical padding
                                                        verticalAlign: 'middle', // Ensure the content is vertically aligned
                                                        textAlign: 'center' // Center the text horizontally
                                                    }} 
                                                    onClick={handleConfirmCode}>Confirm Code
                                                </button>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                            {!isMobile && SocialLogin}
                            {EmaiLogin}        
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Login;
