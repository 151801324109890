import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import BuySellConfirmationForm from './BuySellConfirmationForm';

const BuySellConfirmationModal = (props) => {
    
    return (
    
        <Modal
          show={props.show}
          onHide={props.onHide}
        >
        <Modal.Header closeButton></Modal.Header>
    
        <BuySellConfirmationForm isBuy={props.isBuy} transactionForm={props.transactionForm}/>
        </Modal>
    
        
      );
    };
    
export default BuySellConfirmationModal;
