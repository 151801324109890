import img1 from "../images/box-item/card-item-3.jpg";
import imga1 from "../images/avatar/avt-1.jpg";
import img2 from "../images/box-item/card-item-4.jpg";
import imga2 from "../images/avatar/avt-2.jpg";
import img3 from "../images/box-item/card-item-2.jpg";
import imga3 from "../images/avatar/avt-4.jpg";
import img4 from "../images/box-item/card-item-7.jpg";
import imga4 from "../images/avatar/avt-3.jpg";
import img5 from "../images/box-item/card-item8.jpg";
import imga5 from "../images/avatar/avt-12.jpg";
import img6 from "../images/box-item/card-item-9.jpg";
import imga6 from "../images/avatar/avt-1.jpg";
import img7 from "../images/box-item/image-box-6.jpg";
import imga7 from "../images/avatar/avt-4.jpg";
import img8 from "../images/box-item/image-box-11.jpg";
import imga8 from "../images/avatar/avt-3.jpg";
import imgCollection1 from "../images/avatar/avt-18.jpg";
import kuronoshi from "../images/creators/kuronoshi.png";
import nikolaisavic from "../images/creators/nikolaisavic.png";
import { SocialMediaTypes } from "../../components/layouts/socialMedia";

const todayPickData = [
  {
    imgCreator:
      "https://yt3.googleusercontent.com/ILzXyLo1596fGKpjFWXigDRQGjReXoUGEEbPy9tLQ8I0_Hr2Qu10w1aQMLPXJlj-d1aK0o1nk84=s900-c-k-c0x00ffffff-no-rj",
    creatorHandle: "bobbialthoff",
    creatorName: "Bobbi Althoff",
    creatorProfileLink: "https://www.tiktok.com/@bobbialthoff",
    creatorDescription:
      "Host of The Really Good Podcast. Dry and dark humor with top celebrities.",
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "6.5M",
    views: "8.3M",
  },
  {
    imgCreator:
      "https://pbs.twimg.com/profile_images/1409678692654239744/hJyVzRKm_400x400.jpg",
    creatorHandle: "jayhoovy",
    creatorName: "Jay Hoovy",
    creatorProfileLink: "https://www.tiktok.com/@jayhoovy",
    creatorDescription:
      "Fmr Stanford MBA / Goldman Banker. Founded Stan.Store for Creators, currently at 3mil ARR.",
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "110K",
    views: "12.1K",
  },
  {
    // imgCreator:
    //   "https://yt3.googleusercontent.com/VnctYOKozeEZoVheQhcmMGboWbkQfEetYkeyXrLeI3Qnkqt1kC6uWBvDN56T_xc5PAYqVz9yjn8=s176-c-k-c0x00ffffff-no-rj",
    imgCreator: kuronoshi,
    creatorHandle: "kuronushi_",
    creatorName: "Kuronoshi",
    creatorProfileLink: "https://www.tiktok.com/@kuronushi_",
    creatorDescription:
      "A 22-year-old Japanese artist with exceptional drawing and sculpting abilities. Incredible flair for creativity.",
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "430K",
    views: "5.5m",
  },
  {
    imgCreator: nikolaisavic,
    creatorHandle: "nikolaisavic",
    creatorName: "Nikola Savic",
    creatorProfileLink: "https://www.tiktok.com/@nikolaisavic",
    creatorDescription:
      "The Chef Cook. Coldest transitions there is, better than Zach King.",
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "998.3K",
    views: "45M",
  },
  {
    imgCreator:
      "https://lirp.cdn-website.com/6ce9ac5d/dms3rep/multi/opt/IMG_9657-1ef969a9-640w.JPG",
    creatorHandle: "sadiapsychology",
    creatorName: "Sadia",
    creatorProfileLink: "https://www.tiktok.com/@sadiapsychology",
    creatorDescription:
      "Therapist, Psychologist. Acute observations and controversial analysis about nowaday feminism and hookup culture. ",
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "400.7K",
    views: "280K",
  },
  {
    imgCreator:
      "https://kpopping.com/documents/8e/4/Park-Rossi-fullBodyPicture.webp?v=b3295",
    creatorHandle: "p.rossi_0515",
    creatorName: "Park Rossi",
    creatorProfileLink: "https://www.tiktok.com/@p.rossi_0515",
    creatorDescription:
      'New South Korean singer. Just made debut on February 20, 2023 with the single "Gossip". ',
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "15.5K",
    views: "69.9K",
  },
  {
    imgCreator:
      "https://pbs.twimg.com/profile_images/1100995866628931584/GWugWhRW_400x400.jpg",
    creatorHandle: "yupyork",
    creatorName: "Yup York",
    creatorProfileLink: "https://www.tiktok.com/@yupyork",
    creatorDescription:
      "Long LatAm🇨🇴🇧🇷🇲🇽. Building Cubbo - eCom fulfillment in Latam. Completed pre-series A round led by SV LatAm Capital and Gerdau Next Ventures.",
    socialMediaType: SocialMediaTypes.TIKTOK,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "42.6K",
    views: "8K",
  },
  {
    imgCreator:
      "https://p16.tiktokcdn-us.com/tos-useast5-avt-0068-tx/af73e08c566fe24d5f4ba5176a55164b~c5_720x720.jpeg",
    creatorHandle: "milksandmatcha",
    creatorName: "Milks and Matcha",
    creatorProfileLink: "https://www.tiktok.com/@milksandmatcha",
    creatorDescription:
      "MIT, Ex Trader at Jane Street (top quant firm). Quit to focus on creator economy. Startup vibes.",
    socialMediaType: SocialMediaTypes.TWITTER,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "32.6K",
    views: "8K",
  },
  {
    imgCreator:
      "https://pbs.twimg.com/profile_images/1447700226999943169/53-0OW0C_400x400.jpg",
    creatorHandle: "GregGoesAllIn",
    creatorName: "Greg",
    creatorProfileLink: "https://www.youtube.com/@GregGoesAllIn",
    creatorDescription:
      "Makes serious and comical Poker Content. Just invited Daniel Negreanu to a home game.",
    socialMediaType: SocialMediaTypes.YOUTUBE,
    imgFollowers:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    imgViews:
      "https://cdn1.iconfinder.com/data/icons/vibrancie-action/30/action_060-trending_up-arrow-up-increase-512.png",
    // nameAuthor: "SalvadorDali",
    followers: "71.3K",
    views: "24.5K",
  },
  // {
  //   img: img2,
  //   title: "Space babe - Night 2/25 ",
  //   tags: "bsc",
  //   imgAuthor: imga2,
  //   nameAuthor: "Trista Francis",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   feature: "coming soon",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img3,
  //   title: "CyberPrimal 042 LAN",
  //   tags: "bsc",
  //   imgAuthor: imga3,
  //   nameAuthor: "Freddie Carpenter",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img4,
  //   title: "Crypto Egg Stamp #5 ",
  //   tags: "bsc",
  //   imgAuthor: imga4,
  //   nameAuthor: "Tyler Covington",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img5,
  //   title: "Travel Monkey Club #45 ",
  //   tags: "bsc",
  //   imgAuthor: imga5,
  //   nameAuthor: "SalvadorDali",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img6,
  //   title: "Sir. Lion Swag #371 ",
  //   tags: "bsc",
  //   imgAuthor: imga6,
  //   nameAuthor: "Trista Francis",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img7,
  //   title: "Cyber Doberman #766",
  //   tags: "bsc",
  //   imgAuthor: imga7,
  //   nameAuthor: "Freddie Carpenter",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img8,
  //   title: "Living Vase 01 by Lanz...",
  //   tags: "bsc",
  //   imgAuthor: imga8,
  //   nameAuthor: "Freddie Carpenter",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img1,
  //   title: "The RenaiXance Rising the sun ",
  //   tags: "bsc",
  //   imgAuthor: imga1,
  //   nameAuthor: "SalvadorDali",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img2,
  //   title: "Space babe - Night 2/25 ",
  //   tags: "bsc",
  //   imgAuthor: imga2,
  //   nameAuthor: "Trista Francis",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   feature: "coming soon",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img3,
  //   title: "CyberPrimal 042 LAN",
  //   tags: "bsc",
  //   imgAuthor: imga3,
  //   nameAuthor: "Freddie Carpenter",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img4,
  //   title: "Crypto Egg Stamp #5 ",
  //   tags: "bsc",
  //   imgAuthor: imga4,
  //   nameAuthor: "Tyler Covington",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img5,
  //   title: "Travel Monkey Club #45 ",
  //   tags: "bsc",
  //   imgAuthor: imga5,
  //   nameAuthor: "SalvadorDali",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img6,
  //   title: "Sir. Lion Swag #371 ",
  //   tags: "bsc",
  //   imgAuthor: imga6,
  //   nameAuthor: "Trista Francis",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img7,
  //   title: "Cyber Doberman #766",
  //   tags: "bsc",
  //   imgAuthor: imga7,
  //   nameAuthor: "Freddie Carpenter",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
  // {
  //   img: img8,
  //   title: "Living Vase 01 by Lanz...",
  //   tags: "bsc",
  //   imgAuthor: imga8,
  //   nameAuthor: "Freddie Carpenter",
  //   price: "4.89 ETH",
  //   priceChange: "$12.246",
  //   wishlist: "100",
  //   imgCollection: imgCollection1,
  //   nameCollection: "Creative Art 3D",
  // },
];

export default todayPickData;
