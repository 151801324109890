const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
    namesub: [
      {
        id: 1,
        sub: "Home 01",
        links: "/",
      },
    ],
  },
  {
    id: 1,
    name: "Profile",
    links: "/profile",
    namesub: [
      {
        id: 1,
        sub: "Home 01",
        links: "/",
      },
    ],
  },
  // {
  //   id: 2,
  //   name: "Explore",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Explore 01",
  //       links: "/explore-01",
  //     },
  //     {
  //       id: 2,
  //       sub: "Explore 02",
  //       links: "/explore-02",
  //     },
  //     {
  //       id: 3,
  //       sub: "Explore 03",
  //       links: "/explore-03",
  //     },
  //     {
  //       id: 4,
  //       sub: "Explore 04",
  //       links: "/explore-04",
  //     },
  //     {
  //       id: 5,
  //       sub: "Live Auctions",
  //       links: "/live-auctions",
  //     },
  //     {
  //       id: 6,
  //       sub: "Item Details 01",
  //       links: "/item-details-01",
  //     },
  //     {
  //       id: 7,
  //       sub: "Item Details 02",
  //       links: "/item-details-02",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   name: "Activity",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Activity 01",
  //       links: "/activity-01",
  //     },
  //     {
  //       id: 2,
  //       sub: "Activity 02",
  //       links: "/activity-02",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   name: "Community",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Blog",
  //       links: "/blog",
  //     },
  //     {
  //       id: 2,
  //       sub: "Blog Details",
  //       links: "/blog-details",
  //     },
  //     {
  //       id: 3,
  //       sub: "Help Center",
  //       links: "/help-center",
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   name: "Page",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Authors 01",
  //       links: "/authors-01",
  //     },
  //     {
  //       id: 2,
  //       sub: "Authors 02",
  //       links: "/authors-02",
  //     },
  //     {
  //       id: 3,
  //       sub: "Wallet Connect",
  //       links: "/wallet-connect",
  //     },
  //     {
  //       id: 4,
  //       sub: "Create Item",
  //       links: "/create-item",
  //     },
  //     {
  //       id: 5,
  //       sub: "Edit Profile",
  //       links: "/edit-profile",
  //     },
  //     {
  //       id: 6,
  //       sub: "Ranking",
  //       links: "/ranking",
  //     },
  //     {
  //       id: 7,
  //       sub: "Login",
  //       links: "/login",
  //     },
  //     {
  //       id: 8,
  //       sub: "Sign Up",
  //       links: "/sign-up",
  //     },
  //     {
  //       id: 9,
  //       sub: "No Result",
  //       links: "/no-result",
  //     },
  //     {
  //       id: 10,
  //       sub: "FAQ",
  //       links: "/faq",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   name: "Contact",
  //   links: "/contact-01",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Contact 1",
  //       links: "/contact-01",
  //     },
  //     {
  //       id: 2,
  //       sub: "Contact 2",
  //       links: "/contact-02",
  //     },
  //   ],
  // },
];

export default menus;

// const menus = [
//     {
//         id: 1,
//         name: 'Home',
//         links: '#',
//         namesub: [
//             {
//                 id: 1,
//                 sub: 'Home 01',
//                 links: '/'
//             },
//             {
//                 id: 2,
//                 sub: 'Home 02',
//                 links: '/home-02'
//             },
//             {
//                 id: 3,
//                 sub: 'Home 03',
//                 links: '/home-03'
//             },
//             {
//                 id: 4,
//                 sub: 'Home 04',
//                 links: '/home-04'
//             },
//             {
//                 id: 5,
//                 sub: 'Home 05',
//                 links: '/home-05'
//             },
//             {
//                 id: 6,
//                 sub: 'Home 06',
//                 links: '/home-06'
//             },
//             {
//                 id: 7,
//                 sub: 'Home 07',
//                 links: '/home-07'
//             },
//             {
//                 id: 8,
//                 sub: 'Home 08 (Special)',
//                 links: '/home-08'
//             },
//         ]
//     },
//     {
//         id: 2,
//         name: 'Explore',
//         links: '#',
//         namesub: [
//             {
//                 id: 1,
//                 sub: 'Explore 01',
//                 links: '/explore-01'
//             },
//             {
//                 id: 2,
//                 sub: 'Explore 02',
//                 links: '/explore-02'
//             },
//             {
//                 id: 3,
//                 sub: 'Explore 03',
//                 links: '/explore-03'
//             },
//             {
//                 id: 4,
//                 sub: 'Explore 04',
//                 links: '/explore-04'
//             },
//             {
//                 id: 5,
//                 sub: 'Live Auctions',
//                 links: '/live-auctions'
//             },
//             {
//                 id: 6,
//                 sub: 'Item Details 01',
//                 links: '/item-details-01'
//             },
//             {
//                 id: 7,
//                 sub: 'Item Details 02',
//                 links: '/item-details-02'
//             }

//         ],
//     },{
//         id: 3,
//         name: 'Activity',
//         links: '#',
//         namesub: [
//             {
//                 id: 1,
//                 sub: 'Activity 01',
//                 links: '/activity-01'
//             },
//             {
//                 id: 2,
//                 sub: 'Activity 02',
//                 links: '/activity-02'
//             }
//         ],
//     },
//     {
//         id: 4,
//         name: 'Community',
//         links: '#',
//         namesub: [
//             {
//                 id: 1,
//                 sub: 'Blog',
//                 links: '/blog'
//             },
//             {
//                 id: 2,
//                 sub: 'Blog Details',
//                 links: '/blog-details'
//             },
//             {
//                 id: 3,
//                 sub: 'Help Center',
//                 links: '/help-center'
//             }
//         ],
//     },
//     {
//         id: 5,
//         name: 'Page',
//         links: '#',
//         namesub: [
//             {
//                 id: 1,
//                 sub: 'Authors 01',
//                 links: '/authors-01'
//             },
//             {
//                 id: 2,
//                 sub: 'Authors 02',
//                 links: '/authors-02'
//             },
//             {
//                 id: 3,
//                 sub: 'Wallet Connect',
//                 links: '/wallet-connect'
//             },
//             {
//                 id: 4,
//                 sub: 'Create Item',
//                 links: '/create-item'
//             },
//             {
//                 id: 5,
//                 sub: 'Edit Profile',
//                 links: '/edit-profile'
//             },
//             {
//                 id: 6,
//                 sub: 'Ranking',
//                 links: '/ranking'
//             },
//             {
//                 id: 7,
//                 sub: 'Login',
//                 links: '/login'
//             },
//             {
//                 id: 8,
//                 sub: 'Sign Up',
//                 links: '/sign-up'
//             },
//             {
//                 id: 9,
//                 sub: 'No Result',
//                 links: '/no-result'
//             },
//             {
//                 id: 10,
//                 sub: 'FAQ',
//                 links: '/faq'
//             }
//         ],
//     },
//     {
//         id: 7,
//         name: 'Contact',
//         links: '/contact-01',
//         namesub: [
//             {
//                 id: 1,
//                 sub: 'Contact 1',
//                 links: '/contact-01'
//             },
//             {
//                 id: 2,
//                 sub: 'Contact 2',
//                 links: '/contact-02'
//             }
//         ],
//     },

// ]

// export default menus;
