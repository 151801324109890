let userBalanceData = {
  usdBalance: 300,
  creatorTime: {
    bobbialthoff: 0,
    jayhoovy: 0,
  },
};

let lp = {
  minutesLp: 3000,
  usdLp: 2000,
};

const setUserUsdBalance = (newUsdBalance) => {
  userBalanceData.usdBalance = newUsdBalance;
};

const setUserCreatorTimeHoldings = (creatorHandle, newTimeHoldings) => {
  const creatorHoldings = userBalanceData.creatorTime;
  console.log("current holdings: ", creatorHoldings);
  console.log("test creatorHandle: ", creatorHandle);
  creatorHoldings[creatorHandle] = newTimeHoldings;
};

export { lp, userBalanceData, setUserUsdBalance, setUserCreatorTimeHoldings };
