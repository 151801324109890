// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkI9kZD0acBgOX9IFqNT7bk_xbNx-xhbU",
  authDomain: "creator-project-91207.firebaseapp.com",
  projectId: "creator-project-91207",
  storageBucket: "creator-project-91207.appspot.com",
  messagingSenderId: "1092478955210",
  appId: "1:1092478955210:web:60ab44c28914131ea54e33",
  measurementId: "G-21QZMF2SV4",
};

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig);

export { fbApp };
