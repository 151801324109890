import React from 'react';
import { useState , Fragment } from 'react';
import Footer from '../components/footer/Footer';
import HeaderStyle from '../components/header/HeaderStyle2';
import CardItem from '../components/layouts/home-4/CardItem';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/home-4/LiveAuction';
import TopSeller from '../components/layouts/home-2/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller';
import TodayPicks from '../components/layouts/home-4/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import Create from '../components/layouts/Create';
import Header from '../components/header/Header';
import HeaderStyle2 from '../components/header/HeaderStyle2';


const Home04 = () => {
    return (
        <div className='home-4'>
            <HeaderStyle2 />
            {/* <CardItem /> */}
            {/* <LiveAuction data={liveAuctionData} /> */}
            {/* <TopSeller data={topSellerData} /> */}
            <Fragment>
            <section className="tf-explore tf-section">
            <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-2 mg-bt-13">
                                Invest In Your Favorite Creators
                            </h2>
                            <p className="sub-title ct small mg-bt-20 pad-420">
                                Ever seen a creator that you knew were gonna blew up? What if you could actually invest in them when they are still micro-influencers? No legal documents; No agencies; Simply join waitlist below and you are one step away from making money while scrolling tiktok. 
                            </p>
                        </div>
                    </div>
            </div>
            <TodayPicks data={todayPickData} />
            </section>
            </Fragment>
            {/* <PopularCollection data={popularCollectionData} /> */}
            {/* <Create /> */}
            <Footer />
        </div>
    );
}

export default Home04;
