import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import WaitlistFormUser from './WaitlistFormUser';

const CardModalUserWaitlist = (props) => {
    
return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>

    <WaitlistFormUser 
      socialPlatform={props.socialPlatform}
      socialHandle={props.socialHandle}/>
    </Modal>

    
  );
};

export default CardModalUserWaitlist;
