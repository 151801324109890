import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import MyHoldingsData from '../../assets/fake-data/data-my-holdings';
import CardModalDepositForm from './CardModalDepositForm';
import CardModalWithdrawalForm from './CardModalWithdrawalForm';



const MyInvestments = props => {
    const MyInvestmentsData = props.data;
    const [depositModalShow, setDepositModalShow] = useState(false);
    const [withdrawalModalShow, setWithdrawalModalShow] = useState(false);

    return  (
        <div>
            <div className="meta-item" >
                <div className="left">
                        <h2> Investing </h2>
                </div>
                <div className="right">
                    <button className="icon-fl-credit-card" onClick={() => setDepositModalShow(true)} style={{color: 'white'}}> Deposit</button>
                </div>
                {/* <div className="right">
                    <button className="icon-fl-credit-card" onClick={() => setDepositModalShow(true)} style={{color: 'white'}}> Deposit</button>
                    <span className="viewed eye mg-r-8">225</span>
                    <button className="icon-fl-icon-11" />
                </div> */}
            </div>
            <div className="meta-item-details" style = {{marginTop: '0px'}}>
                    {/* <span className="viewed eye mg-r-8">225</span>
                    <span to="/login" className="liked heart wishlist-button"><span className="number-like">100</span></span> */}
                <div className="item-style-2 item-details">
                    {/* <button className="icon-fl-credit-card" onClick={() => setDepositModalShow(true)} style={{color: 'white', marginBottom: '25px'}}> Deposit</button> */}
                    <ul className="list-details">
                        <li><span><h5> Total Value: </h5> </span><h5> ${MyInvestmentsData.totalInvestmentValue} </h5> </li>
                        <li> 
                            <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%', // This will make the div take up the full width of its parent
                                }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center',
                                }}>
                                    <span> Usd Balance: </span> 
                                    <h6 style={{display: 'inline', }}> ${MyInvestmentsData.usdBalanceValue}</h6>
                                </div>

                                <span style={{ textDecoration: 'underline' }} onClick={() => setWithdrawalModalShow(true)}>withdraw</span>
                            </div>
                        </li>
                        <li><span>Creator Holdings: </span><h6> ${MyInvestmentsData.creatorHoldingsValue} </h6> </li>
                        <li><span>Creator Services: </span><h6> ${MyInvestmentsData.servicesHoldingsValue} </h6> </li>
                        {/* <li><span>Email : </span><h6>@{item.creatorHandle}</h6> </li>
                        <li><span>Balance : </span><h6>{item.socialMediaType}</h6> </li> */}
                        {/* <li><span>Followers : </span><h6>{item.followers}</h6> </li> */}
                        {/* <li><span>Current Views : </span><h6>{item.views}</h6> </li> */}
                    </ul>
                </div>
                {/* <div className="item-style-2 item-details">
                    <button className="icon-fl-logout" onClick={() => setWithdrawalModalShow(true)} style={{color: 'white', marginBottom: '25px'}}> Withdraw</button>
                    <ul className="list-details">
                        <li><span><h5> Total Withdrawable: </h5> </span><h5> ${MyInvestmentsData.usdBalanceValue} </h5> </li>
                        <li><span>Usd Balance: </span><h6> ${MyInvestmentsData.usdBalanceValue}</h6> </li>
                        <li><span>In Orders: </span><h6> 0</h6> </li>
                        <li><span>Processing: </span><h6> 0</h6> </li>
                    </ul>
                </div> */}
            </div>

            <CardModalDepositForm
                show={depositModalShow}
                onHide={() => setDepositModalShow(false)}
            />
            <CardModalWithdrawalForm
                show={withdrawalModalShow}
                onHide={() => setWithdrawalModalShow(false)}
            />
            {/* <DepositForm/> */}
        </div>);
}

export default MyInvestments;
