import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';

const MyServices = props => {
    const MyServicesData = props.data;
    return (<div className="widget widget-recent-post mg-bt-43">
        {/* <h2 className="title-widget mg-bt-23">Recent Post</h2> */}
            <div className="meta-item">
            <div className="left">
                <h2> Services </h2>
            </div>
        </div>
        <ul>
            {
                MyServicesData.map((item,index) => (
                        <li key={index} className="box-recent-post" >
                            <div className="box-feature"><img src={item.img} alt="Axies" /></div>
                            <div className="box-content">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className="title-recent-post">{item.title}</div>
                                    {/* <Link to={`/creator/${item.creatorHandle}#buySellSection`}> */}
                                        <button style={{padding: '5px 20px'}}>Use</button>
                                    {/* </Link> */}
                                </div>
                                <span><Link to={`/creator/${item.creatorHandle}`} className="sub-recent-post">{`By: ${item.creatorName}`}</Link><div className="day-recent-post">{`Item Count: ${item.count}`}</div></span>
                            </div>
                        </li>
                ))
            }
        </ul>
    </div>);
}

export default MyServices;
