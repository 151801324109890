import { fbApp } from "../config/firebaseConfig";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithRedirect,
  getRedirectResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const auth = getAuth(fbApp);
auth.languageCode = "en";
// auth.settings.appVerificationDisabledForTesting = true;

const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithRedirect(auth, provider);
  return result;
};

const signUpWithEmail = async (email, password) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    console.log("Signed up successfully!");
    return result;
  } catch (error) {
    console.error("Error signing up:", error);
  }
};

const signInWithEmail = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    console.log("Signed in successfully!");
    return result;
  } catch (error) {
    console.error("Error signing in:", error);
  }
};

const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset link sent to your email.");
  } catch (error) {
    console.log("Error sending password reset email: " + error.message);
  }
};

// const signInOrCreateWithEmail = async (email, password) => {
//   const auth = getAuth(fbApp);
//   let user;
//   try {
//     // Attempt to sign in
//     console.log("!!!!!!!!!!!!!!!!!!!here!!!!!!!!!!!!!!!!!!!");
//     user = await signInWithEmailAndPassword(auth, email, password);
//     console.log("Signed in successfully!");
//     return user;
//   } catch (signInError) {
//     // Check if the error is due to the user not being found
//     // if (signInError.code === "auth/user-not-found") {
//     try {
//       // Create a "new user since the user was not found
//       console.log("here???");
//       user = await createUserWithEmailAndPassword(auth, email, password);
//       console.log("Signed up and signed in successfully!");
//       return user;
//     } catch (signUpError) {
//       console.error("Error signing up:", signUpError);
//     }
//     // }
//     // } else {
//     //   console.error("Error signing in:", signInError);
//     // }
//   }
// };

const signInWithPhoneSendCode = async (phoneNumber, recaptchaVerifier) => {
  try {
    // const recaptchaVerifier = new RecaptchaVerifier(
    //   "recaptcha-container",
    //   {
    //     size: "invisible",
    //   },
    //   auth
    // );
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      recaptchaVerifier
    );
    // setConfirmation(confirmationResult);
    console.log("Code sent.");
    return confirmationResult;
  } catch (error) {
    console.error("Error sending code:", error);
  }
};

// const signInWithPhoneConfirmCode = async (confirmation, code) => {
//   // if (confirmation) {
//   try {
//     const result = await confirmation.confirm(code);
//     console.log("Authenticated:", result.user);
//     return result;
//   } catch (error) {
//     console.error("Error verifying code:", error);
//   }
//   // }
// };

export {
  signInWithGoogle,
  signUpWithEmail,
  signInWithEmail,
  resetPassword,
  auth,
  signInWithPhoneSendCode,
};
