import React, {useState}from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { userBalanceData, setUserCreatorTimeHoldings } from '../../assets/fake-data/data-balance';


const PurchaseServiceModal = (props) => {
  const serviceData = props.serviceData;
  const creator = props.creator;
  console.log("serviceData: ", serviceData)
  // const handleInputChange = (event) => {
  //     setMinutes(event.target.value);
  // };
  const [confirmed, setConfirmed] = useState(false);

  const creatorHoldings = userBalanceData.creatorTime;

  if (serviceData === null) {
    return <div></div>
  }


return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>
    {confirmed ? <div className="modal-body space-y-20 pd-40">
              <h3>Purchase Service</h3>
              <p className="sub-heading">
                Your balance: {creatorHoldings[creator.creatorHandle].toFixed(2)} minutes
            </p>
              <p>Your purchase of service "{serviceData.title}" by {creator.creatorName} is successful.</p>
          </div> :
  
    <div className="modal-body space-y-20 pd-40">
        <h3>Purchase Service</h3>
        <p className="sub-heading">
            Your balance: {creatorHoldings[creator.creatorHandle].toFixed(2)} minutes
        </p>
        <p>You are purchasing service "{serviceData.title}" for {serviceData.price} minutes of {creator.creatorName}'s time. {serviceData.description} </p>
        <p>Your balance will be deducted by {serviceData.price} minutes. Continue by confirming below.</p>
        {/* <input 
                            type="number" 
                            value={minutes} 
                            onChange={handleInputChange} 
                            placeholder="Number of Minutes"
                        /> */}
        <div className="hr"></div>
        <button onClick={() => {
            setConfirmed(true); 
            setUserCreatorTimeHoldings(creator.creatorHandle, creatorHoldings[creator.creatorHandle] - serviceData.price);
          }} 
          className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close">Confirm</button>
    </div>
}
    </Modal>
    
  );
};

export default PurchaseServiceModal;
