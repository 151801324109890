import React, { useState } from 'react';
import { submitCreatorForm } from '../../util/backendFunctions'
import { userBalanceData, setUserUsdBalance, setUserCreatorTimeHoldings } from '../../assets/fake-data/data-balance';
import { useParams } from 'react-router-dom';

const BuySellConfirmationForm = props => {

  const { creatorHandle } = useParams();
  const [submitted, setSubmitted] = useState(false);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // console.log(formData);
    setSubmitted(true);
    const creatorHoldings = userBalanceData.creatorTime;
    console.log("props.transactionForm: ", props.transactionForm);
    if (props.isBuy) {
      setUserUsdBalance(userBalanceData.usdBalance - Number(props.transactionForm.usd));
      setUserCreatorTimeHoldings(creatorHandle, creatorHoldings[creatorHandle] + props.transactionForm.minutes);
    } else {
      setUserUsdBalance(userBalanceData.usdBalance + Number(props.transactionForm.usd));
      setUserCreatorTimeHoldings(creatorHandle, creatorHoldings[creatorHandle] - props.transactionForm.minutes);
    }
    // window.fbq('track', 'submit-form');
    // await submitCreatorForm(formData);
  };

  // const inputHeaderStyle = {
  //   fontSize: '16px',  // Adjust the font size as needed.
  //   padding: '10px',   // Add more padding for a larger feel.
  //   width: '100%',     // Make the input take the full width of its container.
  // };

  // const inputFieldStyle = {
  //   border: '1px solid #ccc', 
  //   fontSize: '16px',  // Adjust the font size as needed.
  //   padding: '10px',   // Add more padding for a larger feel.
  //   width: '100%',     // Make the input take the full width of its container.
  //   marginBottom: '20px',
  //   WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
  //   MozAppearance: 'none',       // Remove default appearance on Firefox
  //   appearance: 'none', 
  //   marginLeft: '10px'    
  // };

  // const selectStyle = {
  //   background: 'transparent',    // Transparent background
  //   border: '1px solid #ccc',    // A simple border like most text inputs
  //   fontSize: '16px',            // Making font size consistent with text inputs
  //   padding: '10px',             // Padding to give it some space
  //   width: '100%',               // Taking the full width of its container
  //   marginBottom: '20px',        // Bottom margin for spacing
  //   WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
  //   MozAppearance: 'none',       // Remove default appearance on Firefox
  //   appearance: 'none', 
  //   marginLeft: '10px'         // General reset
  // };

  if (submitted) {
    return (
      <div className="modal-body space-y-20 pd-40">
          <h2 className="heading" style={{marginBottom: '40px'}}>{props.isBuy ? "Buy" : "Sell"} Time</h2>
          <p className="sub-heading">
              Your {props.isBuy ? "purchase" : "sell"} of {props.transactionForm.minutes} minutes was successful!
          </p>
      </div>
    )
  }

  return (
    <div className="modal-body space-y-20 pd-40">
        <h2 className="heading" style={{marginBottom: '40px'}}>{props.isBuy ? "Buy" : "Sell"} Time</h2>
        <p className="sub-heading">
            Are you sure you want to swap {props.isBuy ? props.transactionForm.usd : props.transactionForm.minutes} {props.isBuy ? "usd" : "minutes"} for {props.isBuy ? props.transactionForm.minutes : props.transactionForm.usd} {props.isBuy ? "minutes" : "usd"}?
        </p>
        <div style={{ textAlign: 'center' }}>
           <button onClick={handleSubmit}>Confirm</button>
        </div>
    </div>
  )
}

export default BuySellConfirmationForm;
