import img1 from "../images/slider/slide_1.png";
import img2 from "../images/slider/slide_1.png";
import imgbg1 from "../images/slider/bg_slide_1.png";
import imgbg2 from "../images/slider/bg_slide_1.png";

const heroSliderData = [
  {
    title_1: "Find, invest in",
    title_2: "undervalued",
    title_3: "Creators",
    // description:
    //   "Ever scrolled TikTok and spotted a creator you just knew was going to blow up? Imagine you could invest in their talent early on. Well, now you can. Join waitlist below and turn that intuition into cash.",
    description: (
      <p style={{ fontFamily: "Lobster", fontSize: "1.2em" }}>
        Ever scrolled TikTok and spotted a creator you just <i>knew</i> was
        going to blow up? Imagine if you could invest in their talent early on.
        Well, now you can. Join and turn that intuition into cash.
      </p>
    ),
    img: img1,
    imgbg: imgbg1,
    class: "left",
  },
  //   {
  //     title_1: "Discover, find,",
  //     title_2: "Sell extraordinary",
  //     title_3: "Monster NFTs",
  //     description:
  //       "Marketplace for monster character cllections non fungible token NFTs",
  //     img: img2,
  //     imgbg: imgbg2,
  //     class: "center",
  //   },
  //   {
  //     title_1: "Discover, find,",
  //     title_2: "Sell extraordinary",
  //     title_3: "Monster NFTs",
  //     description:
  //       "Marketplace for monster character cllections non fungible token NFTs",
  //     img: img2,
  //     imgbg: imgbg2,
  //     class: "right",
  //   },
];

export default heroSliderData;
