import React, { useState } from 'react';
import todayPickData from '../../assets/fake-data/data-today-pick'
import { SocialMediaTypes } from './socialMedia'
import { submitUserForm } from '../../util/backendFunctions';

const DepositForm = props => {

  const [formData, setFormData] = useState({
    depositAmount: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [depositSubmitted, setDepositSubmitted] = useState(false);

  const handleDepositSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    setDepositSubmitted(true);
    // Handle deposit submission logic here
  };
  


  const inputHeaderStyle = {
    fontSize: '16px',  // Adjust the font size as needed.
    padding: '10px',   // Add more padding for a larger feel.
    width: '100%',     // Make the input take the full width of its container.
  };

  const inputFieldStyle = {
    border: '1px solid #ccc', 
    fontSize: '16px',  // Adjust the font size as needed.
    padding: '10px',   // Add more padding for a larger feel.
    width: '100%',     // Make the input take the full width of its container.
    marginBottom: '20px',
    WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
    MozAppearance: 'none',       // Remove default appearance on Firefox
    appearance: 'none', 
    marginLeft: '10px'    
  };

  const selectStyle = {
    background: 'transparent',    // Transparent background
    border: '1px solid #ccc',    // A simple border like most text inputs
    fontSize: '16px',            // Making font size consistent with text inputs
    padding: '10px',             // Padding to give it some space
    width: '100%',               // Taking the full width of its container
    marginBottom: '20px',        // Bottom margin for spacing
    WebkitAppearance: 'none',    // Remove default appearance on WebKit browsers (e.g., Chrome, Safari)
    MozAppearance: 'none',       // Remove default appearance on Firefox
    appearance: 'none', 
    marginLeft: '10px'         // General reset
  };

  // if (submitted) {
  //   return <div>Thank you for joining our waitlist! We'll notify you once the product goes live.</div>;
  // }
  if (depositSubmitted) {
    return (
        <div className="modal-body space-y-20 pd-40">
            <h1 className="heading" style={{marginBottom: '40px'}}>Success</h1>
            <p className="sub-heading">
                Stripe page. You have now deposited {formData.depositAmount} USD to your account.
            </p>
        </div>
    );
  }

  return (
    <div className="modal-body space-y-20 pd-40">
      <h3>Deposit Funds</h3>
      <p>Begin purchasing time of your favorite creators. Kindly enter the dollar amount you'd like to invest below. You will be redirected you to a Stripe confirmation page.</p>
      <form onSubmit={handleDepositSubmit}>
        <div>
            <label htmlFor="depositAmount" style={inputHeaderStyle}>
                Deposit Amount (USD):
                <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
            </label>
            <input
                type="number"
                id="depositAmount"
                name="depositAmount"
                value={formData.depositAmount}
                onChange={handleChange}
                required
                style={inputFieldStyle}
                min="0"
            />
        </div>
        <div style={{ textAlign: 'center' }}>
          <button type="submit">Confirm</button>
        </div>
      </form>
    </div>
  );
}

export default DepositForm;


// import React, { useState } from 'react';

// const DepositForm = () => {
//   const [formData, setFormData] = useState({
//     depositAmount: '',
//   });

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData(prevData => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log(formData);
//     // Handle deposit submission logic here
//   };

//   const inputHeaderStyle = {
//     fontSize: '16px',
//     padding: '10px',
//     width: '100%',
//   };

//   const inputFieldStyle = {
//     border: '1px solid #ccc',
//     fontSize: '16px',
//     padding: '10px',
//     width: '100%',
//     marginBottom: '20px',
//     WebkitAppearance: 'none',
//     MozAppearance: 'none',
//     appearance: 'none', 
//     marginLeft: '10px'
//   };

//   return (
//     <div className="deposit-form">
//       <h3>Deposit Funds</h3>
//       <p>Start buying time of your favorite creators.</p>
//       <form onSubmit={handleSubmit}>
        // <div>
        //   <label htmlFor="depositAmount" style={inputHeaderStyle}>
        //     Deposit Amount:
        //     <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
        //   </label>
        //   <input
        //     type="number"
        //     id="depositAmount"
        //     name="depositAmount"
        //     value={formData.depositAmount}
        //     onChange={handleChange}
        //     required
        //     style={inputFieldStyle}
        //     min="0"
        //   />
        // </div>

//         <div style={{ textAlign: 'center' }}>
//           <button type="submit">Deposit Now!</button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default DepositForm;
