import React, { useState } from 'react';

const WithdrawalForm = () => {
  const [formData, setFormData] = useState({
    cardName: '',
    cardNumber: '',
    email: '',
    withdrawalAmount: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    // Handle deposit submission logic here
  };

  const inputHeaderStyle = {
    fontSize: '16px',
    padding: '10px',
    width: '100%',
  };

  const inputFieldStyle = {
    border: '1px solid #ccc',
    fontSize: '16px',
    padding: '10px',
    width: '100%',
    marginBottom: '20px',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none', 
    marginLeft: '10px'
  };

  const [withdrawalSubmitted, setWithdrawalSubmitted] = useState(false);

  const handleWithdrawalSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    setWithdrawalSubmitted(true);
    // Handle deposit submission logic here
  };

  if (withdrawalSubmitted) {
    return (
        <div className="modal-body space-y-20 pd-40">
            <h1 className="heading" style={{marginBottom: '40px'}}>Success</h1>
            <p className="sub-heading">
                Withdrawal Confirmed. You are withdrawing {formData.withdrawalAmount} USD to your bank account. Funds should arrive in 2-4 business days.
            </p>
        </div>
    );
  }

  return (
    <div className="modal-body space-y-20 pd-40">
      <h3>Withdraw</h3>
      <form onSubmit={handleWithdrawalSubmit}>
        <div>
          <label htmlFor="cardName" style={inputHeaderStyle}>
            Name on Card:
            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          </label>
          <input
            type="text"
            id="cardName"
            name="cardName"
            value={formData.cardName}
            onChange={handleChange}
            required
            style={inputFieldStyle}
          />
        </div>

        <div>
          <label htmlFor="cardNumber" style={inputHeaderStyle}>
            Card Number:
            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          </label>
          <input
            type="text"
            id="cardNumber"
            name="cardNumber"
            value={formData.cardNumber}
            onChange={handleChange}
            required
            maxLength="16"
            style={inputFieldStyle}
          />
        </div>

        <div>
          <label htmlFor="Email" style={inputHeaderStyle}>
            Email:
            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            maxLength="16"
            style={inputFieldStyle}
          />
        </div>

        <div>
          <label htmlFor="withdrawalAmount" style={inputHeaderStyle}>
           Usd Amount:
            <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
          </label>
          <input
            type="text"
            id="withdrawalAmount"
            name="withdrawalAmount"
            value={formData.withdrawalAmount}
            onChange={handleChange}
            required
            maxLength="16"
            style={inputFieldStyle}
          />
        </div>

        <div style={{ textAlign: 'center' }}>
          <button type="submit" >Confirm Withdrawal</button>
        </div>
      </form>
    </div>
  );
}

export default WithdrawalForm;
