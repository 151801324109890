import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Link, useParams, useLocation, useNavigate} from 'react-router-dom'
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import img1 from '../assets/images/avatar/avt-3.jpg'
import img2 from '../assets/images/avatar/avt-11.jpg'
import img3 from '../assets/images/avatar/avt-1.jpg'
import img4 from '../assets/images/avatar/avt-5.jpg'
import img5 from '../assets/images/avatar/avt-7.jpg'
import img6 from '../assets/images/avatar/avt-8.jpg'
import img7 from '../assets/images/avatar/avt-2.jpg'
import imgdetail1 from '../assets/images/box-item/images-item-details2.jpg'
import todayPickData from '../assets/fake-data/data-today-pick';
import { getSocialMediaTypeInfo } from '../components/layouts/socialMedia';
import ServicesCard from '../components/layouts/ServicesCard';
import MyServicesData from '../assets/fake-data/data-my-services';
// import BuySellModal from '../components/layouts/BuySellModal';
import BuySellComponent from './BuySell';
import { lp } from '../assets/fake-data/data-balance';
import MyServices from '../components/layouts/MyServices';
import MyHoldings from '../components/layouts/MyHoldings';
import MyBasicInfo from '../components/layouts/MyBasicInfo';
import MyInvestments from '../components/layouts/MyInvestments';
import MyInvestmentsData from '../assets/fake-data/data-my-investments';
import MyHoldingsData from '../assets/fake-data/data-my-holdings';
import MySettings from '../components/layouts/MySettings';

const MyProfile = () => {

    const navigate = useNavigate();
    // const location = useLocation();
    // console.log("location state: ", location.state);
    // const user = location.state?.user;
    // console.log("user profile: ", user)
    
    const item = todayPickData[0];



    const getUser = () => {
        return localStorage.getItem('user');
    }

    useEffect(() => {
        const user = getUser();
        if (!user) {

            navigate("/login");
        }
    })

    // if (!user) {
    //     return <p>No user data available.</p>;
    // }

    return (
        <div className='item-details'>
            <Header />
            <div className="tf-section tf-item-details style-2">
                <div className="themesflat-container" style={{marginTop: '50px'}}>
                    {/* <div className="page-title-heading mg-bt-12">
                            <h2 className="heading text-left"> {user.email} </h2>
                    </div> */}
                    <div className="row" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}> 
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    {/* <div className="meta-info">
                                            <div className="author">
                                                <div className="info">
                                                    <span>Followers</span>
                                                    <h6>{item.followers}</h6>
                                                </div>
                                                <div className="avatar">
                                                    <img src={item.imgFollowers} alt="Followers" />
                                                </div>
                                            </div>
                                            <div className="author">
                                                <div className="info">
                                                    <span>Current Views</span>
                                                    <h6>{item.views}</h6>
                                                </div>
                                                <div className="avatar">
                                                    <img src={item.imgViews} alt="Views" />
                                                </div>
                                            </div>
                                        </div> */}
                                    <MyBasicInfo data={MyInvestmentsData}/>
                                    <MyInvestments data={MyInvestmentsData}/>
                                    <MyHoldings data={MyHoldingsData}/>
                                    <MyServices data={MyServicesData}/>
                                    <MySettings/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <Footer />
        </div>
    );
}

export default MyProfile;
