import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import CreatorForm from './WaitlistFormCreator';

const CardModalCreatorWaitlist = (props) => {
    
return (

    <Modal
      show={props.show}
      onHide={props.onHide}
    >
    <Modal.Header closeButton></Modal.Header>

    <CreatorForm 
      socialPlatform={props.socialPlatform}
      socialHandle={props.socialHandle}/>
    </Modal>

    
  );
};

export default CardModalCreatorWaitlist;
